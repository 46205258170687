import React, { forwardRef, useEffect, useState } from "react";
import {
  DropboxLogin,
  SlackLogin,
  LogOutButton,
} from "../buttons/buttons";
import { useAuth0 } from "@auth0/auth0-react";
import { useIndexedDB } from "src/client_side_storage/indexed_db";

export interface User {
  id: string;
  name: string;
  email: string;
  profile_picture: Blob;
}


const UserMenu = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { get } = useIndexedDB("app", "users");
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const fetchUser = async () => {
      if (!user?.sub) return;
      try {
        const response = await get(String(user.sub)) as User;
        console.log(response)
        if (response?.profile_picture instanceof Blob) {
          const url = URL.createObjectURL(response.profile_picture);
          setAvatar(url);
        }
      } catch (error) {
        console.error("Error fetching user avatar:", error);
      }
    };
    fetchUser();
  }, []);

  if (!isAuthenticated) {
    return (
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 h-1/3 grid grid-rows-[1fr_auto] grid-cols-2 gap-10 p-12 bg-neutral-800 text-white rounded-xl shadow-lg z-50 border border-neutral-500"
        ref={ref}
      >
        <p>Please log in to access user information.</p>
      </div>
    );
  }

  return (
    <div
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 h-1/3 grid grid-rows-[1fr_auto] grid-cols-2 gap-10 p-12 bg-neutral-800 text-white rounded-xl shadow-lg z-50 border border-neutral-500"
      ref={ref}
    >
      <div className="flex flex-col gap-4">
        {/* <img
          src={avatar}
          alt="User"
          className="w-24 h-24 rounded-full object-cover"
        /> */}
        <div>
          <div>Username</div>
          <div className="text-xl">{user?.name || "Unknown User"}</div>
        </div>
        <div>
          <div>Email</div>
          <div className="text-xl">{user?.email || "No email available"}</div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-2">
        <div className="mb-4 text-lg">Connect Services</div>
        <DropboxLogin />
        <SlackLogin />
      </div>

      <div className="col-span-2 flex justify-center mt-2">
        <LogOutButton />
      </div>
    </div>
  );
});

export default UserMenu;