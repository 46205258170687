import React, { useContext, useEffect } from "react";
import { EmbeddedMenu } from "../menus/embedded";
import { TerminalInputContainer } from "../terminal/terminal";
import { TerminalContext } from "../terminal/terminal";
import BeatLoader from "../spinners/beatloader";
import { FaCheckCircle, FaCross, FaFile, FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileAlt} from "react-icons/fa";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";

import { MdOutlineErrorOutline } from "react-icons/md";
import { HiOutlineXMark } from "react-icons/hi2";

import styles from './uploaded_files.module.css'
import { upload } from "@testing-library/user-event/dist/upload";
import { LineStyle } from "@mui/icons-material";


export function PromptFileUploadList({ lineIndex, containerIndex }: { lineIndex: number; containerIndex: number }) {
    const terminalContext = useContext(TerminalContext);
    const uploadedFiles = terminalContext?.lines[lineIndex].input.containers[containerIndex].files;
    const uploadStatus = terminalContext?.lines[lineIndex].input.containers[containerIndex].status;

    const isLast = (
        terminalContext?.lines.length === lineIndex + 1 &&
        terminalContext?.lines[lineIndex].input.containers.length === containerIndex + 1
    )

    const isCurrent = terminalContext?.lines.some(
        (line, lIndex) =>
            lIndex === lineIndex &&
            line.input.containers.some(
                (container, cIndex) =>
                    cIndex === containerIndex && container.status === "loading"
            )
    );

    const getFileIcon = (fileName: string) => {
        const extension = fileName.split(".").pop()?.toLowerCase();

        switch (extension) {
            case "pdf":
                return <FaFilePdf style={{color:'#D32F2F'}} className="file-icon" />;
            case "doc":
            case "docx":
                return <FaFileWord style={{color: '#1976D2'}} className="file-icon" />;
            case "xls":
            case "xlsx":
                return <FaFileExcel style={{color: '#388E3C'}} className="file-icon" />;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
                return <FaFileImage style={{color: '#757575'}} className="file-icon" />;
            case "txt":
            case "csv":
                return <FaFileAlt style={{color: '#0288D1'}}  className="file-icon" />;
            default:
                return <FaFile style={{ color: '#616161' }}className="file-icon" />;
        }
    };

    const deleteFile = (selectedFile: number) => {
        if (!uploadedFiles || !terminalContext) {
            console.error("Invalid data structure or missing context");
            return;
        }
        if (terminalContext.sendingQuery === true) return ;
        const updatedFiles = uploadedFiles.filter((_, index) => index !== selectedFile);
        terminalContext.setLines((prevLines) => {
            const updatedLines = [...prevLines];
            const targetLine = updatedLines[lineIndex];
            const targetContainer = targetLine.input.containers[containerIndex];
            updatedLines[lineIndex] = {
                ...targetLine,
                input: {
                    ...targetLine.input,
                    containers: [
                        ...targetLine.input.containers.slice(0, containerIndex),
                        { ...targetContainer, files: updatedFiles },
                        ...targetLine.input.containers.slice(containerIndex + 1),
                    ],
                },
            };
            return updatedLines;
        });
    };
    
    const cancelUpload = () => {
        const cancelToken = terminalContext?.cancelToken;
        if (cancelToken) {
            terminalContext.lines[lineIndex].input.containers[containerIndex].files = [];
            terminalContext?.cancelToken?.abort();
            terminalContext?.setCancelToken(null);
            terminalContext?.setUploadComplete("canceled");
        }
    };

    return (
        <>
            {
                isCurrent && uploadedFiles?.length === 0 && terminalContext?.uploadComplete === "loading" ? (
                <BeatLoader />
            ) : 
            (
                <EmbeddedMenu>

                    {
                        uploadStatus === "loading" ? (
                            <div className={styles.uploaded_files}>
                                <div className={styles.list_items}>
                                    {uploadedFiles?.map((file, index) => (
                                        <div key={index} className="flex flex-row gap-[5px] rounded-md p-[5px] bg-contrast-900">
                                            {getFileIcon(file)}
                                            <span className={styles.file_name}>{file}</span>
                                        </div>
                                    ))}
                                </div>
                                <button onClick={()=>cancelUpload()}><Cross1Icon/>Cancel</button>
                            </div>
                        ):
                        uploadStatus === "success" ? (
                            <div className={styles.uploaded_files}>
                                <div className={styles.status_icon}>
                                    <CheckIcon className="flex " />
                                </div>
                                {uploadedFiles && uploadedFiles?.length >= 0 ? (
                                    <div className={styles.list_items}>
                                        {uploadedFiles?.map((file, index) => (
                                            <div key={index} className={styles.file_item}>
                                                {getFileIcon(file)}
                                                <span className="text-sm">{file}</span>
                                                {isLast ? <button onClick={() => deleteFile(index)}><HiOutlineXMark/></button> : undefined}
                                                
                                            </div>
                                        ))}
                                    </div>
                                    ) : undefined
                                }
                            </div>
                        ):
                        uploadStatus === "error" ? undefined :
                        uploadStatus === "canceled" ? undefined : null
                    }
                </EmbeddedMenu>
            )}
        </>
    );
}


export default function InPromptFile(file_extension : string)
{
    return (
        <i className={`bi ${file_extension} file-icon`}></i>
    )
}