import { AppContext } from '../../pages/chat/chat_app'
import styles from './chat_navbar.module.css'
import React, { useState, useContext, createContext } from 'react'
import BaseButton , { AccountButton, ToggleLightModeButton } from '../buttons/buttons'
import { Home } from '@mui/icons-material'
import { ControlPanelContext } from '../control_panel/control_panel'
import { useIndexedDB } from 'src/client_side_storage/indexed_db'
import logo from '../../static/images/metaloss_Logo_final_white.png'
import logo_text from '../../static/images/metaloss_text_white.png'

import { PlayIcon, FileIcon, EyeOpenIcon, ChatBubbleIcon, ViewVerticalIcon } from '@radix-ui/react-icons'

const NavBarContext = createContext({});


export function NavbarButtons({children}:{children:React.ReactNode})
{ 
    return (
        <div className={styles.navbar_buttons}>
            {children}
        </div>
    )           
}

export default function ChatNavbar()
{
    const [showSettings, setShowSettings] = useState(false);
    const appContext = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);

    return (
        <NavBarContext.Provider value={{showSettings, setShowSettings}}>
            <nav id="navbar" className={`flex flex-col justify-between bg-neutral-950 border-r border-r-neutral-600 pb-5 ${expanded ? 'w-[12%]': 'w-fit' }`}>
                {/* navbar header */}
                <div className='flex flex-row justify-between items-center w-full p-2 mb-5 border-b-neutral-700 border-b'>
                    <div className='flex flex-row items-center'>
                        <img src={logo} alt="" className={`flex ${expanded ? "h-7" : 'h-5'}`}/>
                        {expanded ? <img src={logo_text} className='h-4'/> : undefined}
                    </div>
                    <button onClick={()=>{setExpanded(!expanded)}} className="flex items-center self-center p-1 gap-1 rounded">
                        <ViewVerticalIcon className={`text-white ${expanded ? 'size-4' : 'size-4'}`}/>
                    </button>
                </div>
                <div className="flex flex-col gap-1">
                    <button className={`flex items-center self-center p-2 ${expanded ? "gap-2 w-full" : 'w-fit rounded'} ${appContext?.activeTab === 0 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(0)}}>
                        <ChatBubbleIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs font-bold text-white">Chats</div> : undefined}
                    </button>                    
                    <button className={`flex items-center self-center p-2 ${expanded ? "gap-2 w-full " : 'w-fit rounded'} ${appContext?.activeTab === 1 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(1)}}>
                        <FileIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs text-white">Documents</div> : undefined}
                    </button>
                    <button className={`flex items-center self-center p-2 ${expanded ? " gap-2 w-full " : 'w-fit rounded'} ${appContext?.activeTab === 2 ? 'bg-neutral-800': ''}`} onClick={() => {appContext?.setActiveTab(2)}}>
                        <EyeOpenIcon className={`text-white ${expanded ? 'size-4' : 'size-5'}`}/>
                        {expanded ? <div className="text-xs text-white">Graph</div> : undefined}
                    </button>
                </div>

                <NavbarButtons>
                    {/* <ToggleLightModeButton onClick={() => appContext?.setTheme(appContext?.theme === 'light' ? 'dark' : 'light')}></ToggleLightModeButton> */}
                    <AccountButton onClick={()=>{appContext?.setShowMenu(appContext.showMenu === true ? false : true)}}></AccountButton>
                </NavbarButtons>
            </nav>
        </NavBarContext.Provider>
    )
}