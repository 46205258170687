import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'src/pages/chat/chat_app';
import { TerminalLineI } from './terminal';
import useAxiosAuthClient from 'src/network/interceptors';

const useChat = () => {
  const appContext = useContext(AppContext);
  const [currentChat, setCurrentChat] = useState(appContext?.currentChat);
  const AxiosAuthClient = useAxiosAuthClient();
  const [lines, setLines] = useState<TerminalLineI[]>([]);

  useEffect(() => {
    const fetch_chat = async () => {
        if (appContext?.currentChat) {
            setCurrentChat(appContext.currentChat);
        }
        const response = await AxiosAuthClient.get(`/chat/${currentChat}`);
        console.log("retrieved chat", response);
        setLines(response.data.conversation);
    }

    fetch_chat();
  }, [appContext?.accessToken, appContext?.currentChat]);

  return lines;
};

export default useChat;