import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import envVariables from './env';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Auth0Provider
    domain={envVariables.auth0Domain}
    clientId={envVariables.auth0ClientId}
    authorizationParams={{
      redirect_uri: envVariables.hostname + '/app',
      audience: envVariables.auth0Audience,
      scope: "openid profile email read:current_user update:current_user_metadata"
    }}
    useRefreshTokens={true}
  >
    <App/>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
