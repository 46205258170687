import React, { createContext, useContext, useState, useCallback } from "react";
import * as Toast from "@radix-ui/react-toast";

interface ToastContextValue {
  showToast: (message: string, actionText?: string, onActionClick?: () => void) => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

export const useToast = (): ToastContextValue => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [actionText, setActionText] = useState<string | undefined>(undefined);
    const [onActionClick, setOnActionClick] = useState<(() => void) | undefined>(undefined);
  
    const showToast = useCallback((msg: string, action?: string, onClick?: () => void) => {
      setMessage(msg);
      setActionText(action);
      setOnActionClick(() => onClick);
      setOpen(true);
    }, []);
  
    return (
      <ToastContext.Provider value={{ showToast }}>
        {children}
        <Toast.Provider swipeDirection="right">
          <Toast.Root
            className={`
                flex flex-wrap w-[300px] max-w-[300px] sm:max-w-[500px]
                ${
                  actionText === "success" ? "bg-green-100" :
                  actionText === "error" ? "bg-red-100" :
                  actionText === "info" ? "bg-blue-100" :
                  actionText === "warning" ? "bg-yellow-100" :
                  actionText === "pending" ? "bg-neutral-100" :
                  "bg-[var(--background-color-contrast)] text-[var(--text-color)]"
                } 
                p-2 rounded-lg shadow-lg flex items-center justify-between gap-3 animate-slide-in-right
              `}
            open={open}
            onOpenChange={setOpen}
          >
            <div className="flex flex-col">
              <Toast.Title className="font-normal text-xs">{message}</Toast.Title>
            </div>
            {/* {actionText && (
              <Toast.Action asChild altText={actionText}>
                <button
                  className={`${actionText === "success" ? "bg-green-500 hover:bg-green-600" : 
                    actionText === "error" ? "bg-red-500 hover:bg-red-600" : 
                    actionText === "info" ? "bg-blue-500 hover:bg-blue-600" : 
                    actionText === "warning" ? "bg-yellow-500 hover:bg-yellow-600" : 
                    actionText === "pending" ? "bg-neutral-500 hover:bg-neutral-600" : 
                    ""} text-[var(--text-color)] px-3 py-1 rounded-md text-xs`}
                  onClick={() => {
                    onActionClick?.();
                    setOpen(false);
                  }}
                >
                  {actionText}
                </button>
              </Toast.Action>
            )} */}
            <Toast.Close
              className="text-neutral-300 hover:text-[var(--text-color)] cursor-pointer text-sm"
              aria-label="Close"
            >
              ✕
            </Toast.Close>
          </Toast.Root>
          <Toast.Viewport
            className="fixed bottom-5 right-5 flex flex-col gap-3 z-50"
          />
        </Toast.Provider>
      </ToastContext.Provider>
    );
  };
