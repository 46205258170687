import styles from './buttons.module.css'
import React, { useContext, useEffect, useState } from 'react'
import envVariables from 'src/env';
import {SunIcon, PersonIcon } from '@radix-ui/react-icons';
import { FaGoogle, FaDropbox, FaMicrosoft, FaSlack } from "react-icons/fa";
import { MdDriveFolderUpload } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useIndexedDB } from 'src/client_side_storage/indexed_db';
import { AppContext } from 'src/pages/chat/chat_app';
import ChangePasswordForm from '../forms/change_pass';

import { UploadIcon } from '@radix-ui/react-icons';
import * as Avatar from "@radix-ui/react-avatar";
import { User } from '../menus/user_menu';

interface BaseButtonElements
{
    className?: string;
    children : React.ReactNode | null;
    onClick: React.MouseEventHandler<HTMLButtonElement> | null;
    label : string | null;
}

export default function BaseButton({ className, children, onClick, label} : BaseButtonElements)
{
    if (children)
    {
        return (
            <button className={`${styles.base_button} ${className}`} onClick={onClick ? onClick : undefined}>
                {children}
            </button>
        )
    }
    else
    {
        return (
            <button className={`${styles.base_button} ${className}`} onClick={onClick ? onClick : undefined}>
                {label}
            </button>
        )
    }
}

export function UploadButton({onClick} : { onClick: React.MouseEventHandler<HTMLButtonElement> | null })
{
    return (
        <BaseButton label={null} onClick={onClick}>
            <UploadIcon />
        </BaseButton>
    )
}

export function SaveButton()
{
    return (
        <BaseButton label={null} onClick={null}>
            {"Save"}
        </BaseButton>
    )
}

export function ToggleLightModeButton({onClick} : { onClick: React.MouseEventHandler<HTMLButtonElement> | null })
{
    console.log("toggle mode")
    return(
        <BaseButton label={null} onClick={onClick}><SunIcon/></BaseButton>
    )
}

// export function ToggleSideBarButton()
// {
//     return (
//         <BaseButton label={null} onClick={null} className="toggle_sidebar">
//             <BsLayoutSidebarReverse className={styles.button_icon}/>
//         </BaseButton>
//     )
// }


export function AccountButton({ onClick } : {onClick :  React.MouseEventHandler<HTMLButtonElement> | null}) {
    const { get } = useIndexedDB("app", "users");
    const [avatar, setAvatar] = useState<string | undefined>(undefined);
    const { user } = useAuth0();
    const appContext = useContext(AppContext);
  
    useEffect(() => {
      const fetchUser = async () => {
        if (!user?.sub) return;
        try {
          const response = await get(String(user.sub)) as User;
          console.log(response)
          if (response?.profile_picture instanceof Blob) {
            const url = URL.createObjectURL(response.profile_picture);
            setAvatar(url);
            appContext?.setAvatarURL(url);
          }
        } catch (error) {
          console.error("Error fetching user avatar:", error);
        }
      };
      fetchUser();
    }, []);
  
    return (
      <BaseButton label={null} onClick={onClick} className="relative">
        <Avatar.Root className="inline-flex items-center justify-center w-8 h-8 rounded-2xl bg-neutral-200 overflow-hidden">
          {avatar ? (
            <Avatar.Image
              src={avatar}
              alt="User Avatar"
              className="w-full h-full object-cover"
            />
          ) : (
            <Avatar.Fallback
              className="flex items-center justify-center w-full h-full bg-neutral-300 text-neutral-700 text-sm"
            >
              {user?.name?.charAt(0) || "?"}
            </Avatar.Fallback>
          )}
        </Avatar.Root>
      </BaseButton>
    );
  }

// export function SettingsButton()
// {
//     return (
//         <BaseButton label={null} onClick={null} className="">
//             <BsList className={styles.button_icon}/>
//         </BaseButton>
//     )
// }

export function ChangePassButton()
{
    const [changePass, setChangePass] = useState(false);

    return (
        (
            changePass === true ? <ChangePasswordForm/> : 
            <BaseButton onClick={()=>(setChangePass(changePass ? false : true))} children={null} label={"Change Password"}/>
        )
        
    )
}

export function LogOutButton()
{
    const navigate = useNavigate();
    const { logout } = useAuth0();

    return (
        <BaseButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} children={null} label={"Logout"}></BaseButton>
    )
}

export function GoogleLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/google`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaGoogle className={styles.button_icon}/>} label={"Logout"}></BaseButton>
    )
}

export function DropboxLogin(){
    const {loginWithRedirect} = useAuth0();
    const handleLoginWithDropbox = () => loginWithRedirect({authorizationParams:{ connection: 'dropbox' }});

    return (
        <BaseButton onClick={()=>loginWithRedirect()} children={<FaDropbox  className={styles.button_icon}/>} label={"Logout"}></BaseButton>
    )
}

export function MicrosoftLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/microsoft-login`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaMicrosoft  className={styles.button_icon}/>} label={"Logout"}></BaseButton>
    )
}

export function SlackLogin()
{
    const handleLogin = () => {
        window.location.href = `/api/v1/auth/slack-login`;
    };

    return (
        <BaseButton onClick={handleLogin} children={<FaSlack  className={styles.button_icon}/>} label={"Logout"}></BaseButton>
    )
}