import React from "react";

export default function BarLoader() {
    return (
        <div className="w-[100%] p-1">
            <div className="relative w-full h-1 rounded-full overflow-hidden">
                <div className="top-0 w-[200%] h-1 bg-gradient-to-r from-neutral-700 via-blue-800 to-neutral-800 animate-loading"></div>
            </div>
        </div>
    )
}