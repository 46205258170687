import React, { useEffect, createContext } from "react";
import useAxiosAuthClient from "src/network/interceptors";
import { CircleSpinner } from "react-spinners-kit";
import { CopyIcon, Pencil1Icon, PlayIcon, TimerIcon, TrashIcon, Link2Icon, ChatBubbleIcon, PlusIcon } from "@radix-ui/react-icons";
import { RiRobot2Line } from "react-icons/ri";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "../../notification/toast";

import { UserAvatarContainer, AIAvatar } from "../../avatar/useAvatar";

import { AppContext } from "src/pages/chat/chat_app";
import { PromptContext } from "./usePromptContext";
import { Axios } from "axios";
import { Build } from "@mui/icons-material";

export interface Agent {
  id: string;
  instructions: string;
  input_folder: string;
  output_folder: string;
  status: "pending" | "error" | "success" | "interrupted" | "timeout";
  last_updated: string;
}

export interface Chat {
  name : string;
  id : string;
  user : string;
  workflows : Build[];
}

export interface Build {
  id: string;
  user: string;
  status: string;
  name: string;
  last_updated: string;
  build: { [key: string] : Agent };
}

export interface Thread {
  thread_id: string;
  created_at: string;
  updated_at: string;
  metadata: Record<string, any>;
  status: "idle" | "busy" | "interrupted" | "error";
  values: Record<string, any>;
}



const fakePrompts : Build[] = [{
  id: "dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e", // Build ID from fakePrompts
  user: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31", // User ID from fakePrompts
  status: "pending", // Based on the prompt status
  name: "One agent two agents three agents four agentsanother agent six agent", // Simplified name from the provided example
  last_updated: "2025-01-03T12:42:07.944000", // Based on the last_updated field in fakePrompts
  build: {
    "2b55fae3-b8bb-53b8-a95e-295cfb702c99": {
      id: "2b55fae3-b8bb-53b8-a95e-295cfb702c99",
      instructions: "one agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/0",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/0",
      last_updated : "2025-01-03T12:42:07.944000",
      status: "pending"
    },
    "57682573-632e-58c9-9374-7623ba972cc4": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "57682573-632e-58c9-9374-7623ba972cc4",
      instructions: "two agents",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/0",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/1",
      status: "pending"
    },
    "4286df45-1380-5f0e-aeb3-37215f42f674": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "4286df45-1380-5f0e-aeb3-37215f42f674",
      instructions: "three agents",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/1",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/2",
      status: "pending"
    },
    "90fe7848-8008-5a7e-9999-f4c18e58770c": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "90fe7848-8008-5a7e-9999-f4c18e58770c",
      instructions: "four agents",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/2",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/3",
      status: "pending"
    },
    "259884f8-00d2-5f99-936a-85ca8758c5b2": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "259884f8-00d2-5f99-936a-85ca8758c5b2",
      instructions: "another agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/3",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/4",
      status: "pending"
    },
    "8d428567-b9a7-5009-916e-9cf0f5b074d1": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "8d428567-b9a7-5009-916e-9cf0f5b074d1",
      instructions: "sixth agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/4",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/5",
      status: "pending"
    },
    "a61100bf-62de-5353-aaed-7e5fcd0c5d35": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "a61100bf-62de-5353-aaed-7e5fcd0c5d35",
      instructions: "new agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/5",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/6",
      status: "pending"
    },
    "4c2d027b-d597-5af7-a592-3bd418584fd0": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "4c2d027b-d597-5af7-a592-3bd418584fd0",
      instructions: "helo",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/6",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/7",
      status: "pending"
    },
    "a61100bf-62de-353-aaed-7e5fcd0c5d35": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "a61100bf-62de-5353-aaed-7e5fcd0c5d35",
      instructions: "new agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/5",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/6",
      status: "pending"
    },
    "a61100bf-62de-535-aaed-7e5fcd0c5d35": {
      last_updated : "2025-01-03T12:42:07.944000",
      id: "a61100bf-62de-5353-aaed-7e5fcd0c5d35",
      instructions: "new agent",
      input_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/5",
      output_folder: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31/builds/dc9d9767-4ca8-5593-b02b-3b41b1e8ac7e/6",
      status: "pending"
    },
  }
}];

const fakeChats: Chat[] = [
  {
    id: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31",
    user: "943a344a398dcaaa43163ec44aa8b9bce8c3e805781493b38c2e208878eccb31",
    name: "TEST Chat 1",
    workflows: fakePrompts,
  },
];
export interface IPromptContext {
  selectedBuild: Build | undefined;
  setSelectedBuild: (build: Build) => void;
  selectedAgent: Agent | undefined;
  setSelectedAgent: (agent: Agent) => void;
}

export const PromptsContext = createContext<IPromptContext | undefined>(undefined);



export function PromptEditor(){
  return (
    <div>
      <input type="text" />
    </div>
  )
}

export default function Prompts(){

  const [selectedBuild, setSelectedBuild] = React.useState<Build|undefined>(undefined);
  const [selectedAgent, setSelectedAgent] = React.useState<Agent|undefined>(undefined);


  return (
    <PromptsContext.Provider value={{selectedBuild, setSelectedBuild, selectedAgent, setSelectedAgent}}>
      <div className="flex flex-row w-[100%] h-full box-border text-white rounded">
        <div className="h-[100%] w-[30%] rounded">
          <PromptList />
        </div>
        <div className="flex items-center justify-center content-center h-[100%] w-[70%] rounded p-5 overflow-y-auto">
          <PromptDisplay />
        </div>
      </div>
    </PromptsContext.Provider>
  )
}

export function AgentMessages() {

  const appContext = React.useContext(AppContext);
  const promptsContext = React.useContext(PromptsContext);

  return (
    <div className="flex flex-row w-[100%] gap-4 p-4">
    </div>
  );
}

export function AgentFiles() {
  
  const appContext = React.useContext(AppContext);
  const promptsContext = React.useContext(PromptsContext);

  return (
    <div className="flex flex-row w-[100%] gap-4 p-4 border border-white">
    </div>
  );
}

export function AgentIntermediateSteps() {
  
  const appContext = React.useContext(AppContext);
  const promptsContext = React.useContext(PromptsContext);

  return (
    <div className="flex flex-row w-[100%] gap-4 p-4 border border-white">
    </div>
  );
}


export function PromptDisplay() {
  const promptsContext = React.useContext(PromptsContext);
  const {showToast} = useToast();
  const AxiosAuthClient = useAxiosAuthClient();
  const [selectedThreadState, setSelectedThreadState] = React.useState<Thread | null>(null);
  const [selectedThreadValue, setSelectedThreadValue] = React.useState<any | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = React.useState<Set<string>>(new Set());
  const [initialInstruction, setInitialInstruction] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<string>("");
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const toggleContent = (key: string) => {
    setExpandedKeys((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  };

  const handleUpdateInstructions = async () => {
    try{
      if (promptsContext?.selectedAgent && promptsContext?.selectedBuild)
      {
        const response = await AxiosAuthClient.patch(`/agents/builds/${promptsContext.selectedBuild.id}/${promptsContext.selectedAgent.id}`,
          {params: {new_instructions : promptsContext.selectedAgent.instructions,}}
        ).then((response)=>{
          if (response.status === 204 || response.status === 200){
            
            showToast("Instructions updated", "success");
          }
        });
      }
    } catch {
      console.error("Failed to update instructions");
    }
  }

  useEffect(() => {
    const fetchThread = async () => {
      if (promptsContext?.selectedAgent?.id && promptsContext?.selectedBuild?.id) {
        setLoading(true);
        setError(null);
        setInitialInstruction(promptsContext?.selectedAgent?.instructions);
        try {
          const response = await AxiosAuthClient.get<Thread>(
            `/agents/builds/${promptsContext.selectedBuild.id}/${promptsContext.selectedAgent.id}`
          );
          setSelectedThreadState(response.data);
        } catch (error: any) {
          console.error("Failed to fetch thread state:", error);
          setError(error.response?.data?.detail || "An unexpected error occurred.");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchThread();
  }, [promptsContext?.selectedAgent?.id]);

  return loading ? (
    <div className="flex items-center justify-center h-full w-full">
      <CircleSpinner size={30} className="text-contrast-50" />
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : selectedThreadState === null ? null : (
    <div className="flex flex-col h-[100%] w-[100%] p-2 gap-4">
      {/* Thread Info */}
      <div className="flex flex-col gap-2 bg-neutral-900 rounded p-4 mb-4">
        <div className="">
          <span className="text-sm text-[var(--text-color)]">Agent ID: {selectedThreadState?.thread_id}</span>
        </div>
        <div className="">
          <span className="text-sm text-[var(--text-color)]">Created At: {new Date(selectedThreadState?.created_at).toLocaleString()}</span>
        </div>
        <div className="">
          <span className="text-sm text-[var(--text-color)]">Updated At: {new Date(selectedThreadState?.updated_at).toLocaleString()}</span>
        </div>
        <div className="">
          <span className="text-sm text-[var(--text-color)]">Status: </span>
          <span
            className={`text-sm ${
              selectedThreadState?.status === "idle"
                ? "text-green-500"
                : selectedThreadState?.status === "busy"
                ? "text-yellow-500"
                : selectedThreadState?.status === "interrupted"
                ? "text-red-500"
                : "text-neutral-500"
            }`}
          >
            {selectedThreadState.status}
          </span>
        </div>
      </div>

      {
        /* TODO: Finish this component, needs to send an update to edit the build instructions in mongo 
        and execute a run only for the thread. Optimal version All threads dependant need to rerun */
      }
      <div className="flex flex-col max-h-[30%] w-[100%] gap-4">
        <div className="text-[var(--text-color)]">Instructions</div>
        <textarea
          ref={inputRef}
          placeholder="Enter instructions here..."
          contentEditable={false}
          className="max-h-[300px] border border-[var(--border-line-color)] overflow-y-hidden bg-transparent border-none resize-none outline-none text-[var(--text-color)] leading-[1.5em]"
          value={promptsContext?.selectedAgent?.instructions || ""}
          onChange={(e) => {
            if (promptsContext?.selectedAgent?.id) {
              promptsContext.setSelectedAgent({
                ...promptsContext.selectedAgent,
                instructions: e.target.value,
              });
            } else {
              console.error("Agent ID is undefined, cannot update.");
            }
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (promptsContext?.selectedAgent?.instructions.length === 0) {
                showToast("Instructions are empty", "error");
              }
              if (initialInstruction === promptsContext?.selectedAgent?.instructions) {
                showToast("Instructions have not changed", "info");
              }
              else {
                setOpenDialog(true);
                setAction("confirm_instructions");
              }
            }
          }}
          onInput={(e) => {
            const textarea = e.target as HTMLTextAreaElement;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
          }}
        />
      </div>


      {/* Shows the output from the agent */}
      {selectedThreadState?.values && (
        <div className="flex flex-col gap-5">
          {Object.keys(selectedThreadState.values).map((key, index) => (
            <div className="w-[100%] flex flex-col" key={index}>
              <button
                className="flex flex-col w-[100%] border border-[var(--border-line-color)] rounded p-2 text-left"
                onClick={() => {
                  toggleContent(key);
                  setSelectedThreadValue(selectedThreadValue === selectedThreadState.values[key] ? null : selectedThreadState.values[key])}
                }
              >
                {key.toString()}
                {expandedKeys.has(key) &&
                  key === "messages" ? <AgentMessages/> :
                  key === "files" ? <AgentFiles/> :
                  key === "intermediatesteps" ? <AgentIntermediateSteps/> : undefined
                }
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Dialog for confirming instructions */}
      <Dialog.Root open={(openDialog && action === "confirm_instructions")} onOpenChange={setOpenDialog}>
      <Dialog.Trigger asChild />
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded shadow-lg w-96">
          <h2 className="text-xl font-semibold mb-4">Run Agent</h2>
          {promptsContext?.selectedBuild && (
            <form>
              <div className="mb-4">
                Are you sure you want rerun this step with this instructions?
                <div className="text-xs text-[var(--text-color)] mt-2">
                  {promptsContext?.selectedAgent?.instructions}
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setOpenDialog(false)}
                  className="bg-neutral-300 px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={async (e) => {
                    e.preventDefault();
                    await handleUpdateInstructions();
                    setOpenDialog(false);
                  }}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Confirm
                </button>
              </div>
            </form>
          )}
        </Dialog.Content>
      </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

export function AgentWindow(){
  const promptsContext = React.useContext(PromptsContext);


  return (
    <div>
      {promptsContext?.selectedAgent?.id}
    </div>
  )
}

export function PromptList() {
  const appContext = React.useContext(AppContext);
  const promptsContext = React.useContext(PromptsContext);
  const [prompts, setPrompts] = React.useState<Build[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  
  const [filteredPrompts, setFilteredPrompts] = React.useState<Build[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>("");
  const [action, setAction] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [expandedPrompt, setExpandedPrompt] = React.useState<string | null>(null);
  const [lastFetchedBuild, setLastFetchedBuild] = React.useState<string | undefined>(undefined);
  const [lastFetchedAgent, setLastFetchedAgent] = React.useState<string | undefined>(undefined);
  const AxiosAuthClient = useAxiosAuthClient();
  const {isAuthenticated} = useAuth0();
  const { showToast } = useToast();

  const togglePrompt = (id: string) => {
    setExpandedPrompt((prev) => (prev === id ? null : id));
  };

  useEffect(() => {
      const fetchStatus = async () => {
        if (promptsContext?.selectedBuild?.id) {
          const response = await AxiosAuthClient.put(
            "/agents/builds/status",
            { params: { build_id: promptsContext?.selectedBuild.id } }
          ).then((response)=>{
            promptsContext.setSelectedBuild(response.data);
            setLastFetchedBuild(promptsContext?.selectedBuild?.id);
          });
          console.log(response);
      }
    }
    if (
      promptsContext?.selectedBuild?.id &&
      promptsContext?.selectedBuild?.id !== lastFetchedBuild
    ) {
      fetchStatus();
    }
    else {
      return;
    }
  }, [promptsContext?.selectedBuild, promptsContext?.selectedAgent?.instructions]);

  const handleSubmit = async (event: React.FormEvent, action : string) => {
    event.preventDefault();
    if (promptsContext?.selectedBuild) {
      const newName = promptsContext?.selectedBuild.name;
      try {
        switch (action) {
          case "execute":
            await AxiosAuthClient.post(
              `/agents/builds/${promptsContext?.selectedBuild.id}/run`,
            ).then((response)=>{
              if (response.status === 204 || response.status === 200){
                showToast("Running Prompt", "success");
              }
              else{
                showToast("Unable to run prompt", "error");
              }
            });
            break;
          case "patch":
            await AxiosAuthClient.patch(
              `/agents/builds/${promptsContext?.selectedBuild.id}`,
              null,
              { params: { new_name: newName } }
            ).then((response)=>{
              if (response.status === 204 || response.status === 200){
                showToast("Prompt updated successfully", "success");
              }
              else{
                showToast("Failed to update prompt", "error");
              }
            });
            break;
          case "delete":
            await AxiosAuthClient.delete(`/agents/builds/${promptsContext?.selectedBuild.id}`).then((response)=>{
              if (response.status === 204 || response.status === 200){
                showToast("Prompt deleted successfully", "success");
              }
              else
              {
                showToast("Failed to delete prompt", "error");
              }
            });
            break;
        }
      } catch (error) {
        console.error("Error updating prompt:", error);
        setError("Failed to update prompt name.");
      }
      finally{
        setOpenDialog(false);
        fetchPrompts();
      }
    }
  };

  const fetchPrompts = async () => {
    try {
      setLoading(true);
      const response = await AxiosAuthClient.get("/agents/builds");
      const sortedPrompts = response.data.sort(
        (a: Agent, b: Agent) =>
          new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime()
      );
      setPrompts(sortedPrompts);
      setFilteredPrompts(sortedPrompts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to load prompts.");
      console.error(error);
    }
  };
  
  useEffect(() => {
    fetchPrompts();
  }, [appContext?.accessToken, appContext?.update?.prompts]);

  const updateFilteredPrompts = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.currentTarget.value.toLowerCase();
    setSearchQuery(query);
    setFilteredPrompts(
      prompts.filter((prompt) => prompt.name.toLowerCase().includes(query))
    );
  };

    return (
    <div className="flex flex-col h-[100%] box-border text-contrast-50 bg-neutral-950 border-r border-r-neutral-600">
      <div className="flex flex-col justify-between w-full h-[100%] rounded p-2 gap-2">
        <input
          type="text"
          value={searchQuery}
          onChange={updateFilteredPrompts}
          onFocus={(e) => e.target.placeholder = ""}
          onBlur={(e) => e.target.placeholder = searchQuery === "" ? "Search" : ""}
          placeholder="Search"
          className="flex w-[100%] p-1 text-xs text-[var(--text-color)] bg-[var(--backgound-color-contrast)] h-fit outline-none rounded border border-[var(--border-line-color)]"
        />
        
        <div className="max-h-[100%] overflow-y-auto h-[100%] w-[100%] mr-2">
          <div className="flex flex-col h-fit w-[100%] gap-2 box-border overflow-y-auto">
            <div className="flex flex-col w-[100%] justify-start h-[100%] rounded gap-2">
              {filteredPrompts.map((prompt) => (
                <React.Fragment key={prompt.id}>
                  {/* Parent row */}
                  <div
                    onClick={() => {
                      togglePrompt(prompt.id);
                      promptsContext?.setSelectedBuild(prompt);
                    }}
                    className="cursor-pointer hover:bg-neutral-800 group p-2 text-xs"
                  >
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-col gap-1">
                        <span className="text-xs">{prompt.name}</span>
                        <span className="text-xs text-neutral-500">{new Date(prompt.last_updated).toLocaleString()}</span>
                        <span className={`ml-1 ${
                            prompt.status === "success"
                              ? "text-green-500"
                              : prompt.status === "pending"
                              ? "text-yellow-500"
                              : prompt.status === "timeout"
                              ? "text-neutral-300"
                              : prompt.status === "error"
                              ? "text-red-500"
                              : prompt.status === "interrupted" ?
                              "text-orange-500"
                              :""
                          }`}>{prompt.status}</span>
                        {/* prompt editing buttons */}
                        <div className="flex flex-row gap-1 group-hover:visible invisible">
                          <button
                            disabled={!(prompt.status === "success" || prompt.status === "interrupted" || prompt.status === "error")}
                            onClick={(event) => {
                              event.stopPropagation();
                              promptsContext?.setSelectedBuild(prompt);
                              if (prompt.status === "success" || prompt.status === "interrupted" || prompt.status === "error") {
                                setAction("execute");
                                setOpenDialog(true);
                              } else {
                                showToast("Prompt is already running", "info");
                              }
                            }}
                            className={`flex items-center text-center p-1 group rounded
                              ${
                                !(prompt.status === "completed" || prompt.status === "failed")
                                  ? "opacity-50 cursor-not-allowed"
                                  : "opacity-100 hover:opacity-80"
                            }`}
                          >
                            <PlayIcon className="text-[var(--text-color)] hover:fill-green-500" />
                          </button>
                          <button
                            disabled={true}
                            onClick={(event) => {
                              event.stopPropagation();
                              promptsContext?.setSelectedBuild(prompt);
                              setAction("patch");
                              setOpenDialog(true);
                            }}
                            className={`flex items-center text-center p-1 rounded 
                              ${
                                !(prompt.status === "completed" || prompt.status === "failed")
                                  ? "opacity-50 cursor-not-allowed"
                                  : "opacity-100 hover:opacity-80"
                              }`}
                            >
                              <TimerIcon className="text-[var(--text-color)]" />
                          </button>
                          <button
                              disabled={undefined}
                              onClick={(event) => {
                                event.stopPropagation();
                                promptsContext?.setSelectedBuild(prompt);
                                setAction("patch");
                                setOpenDialog(true);
                              }}
                              className={`flex items-center text-center p-1 rounded`}
                            >
                              <Pencil1Icon className="text-contrast-50" />
                          </button>
                          <button
                            onClick={(event) => {
                              event.stopPropagation();
                              promptsContext?.setSelectedBuild(prompt);
                              setAction("delete");
                              setOpenDialog(true);
                            }}
                            className="group flex items-center text-center p-1"
                            >
                              <TrashIcon className="text-white hover:fill-red-500 hover:text-red-500" />
                          </button>
                          <button
                            className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 items-center justify-center p-1 rounded bg-[var(--background-color)] ml-auto"
                            onClick={(event) => {
                              event.stopPropagation();
                              navigator.clipboard.writeText(prompt.id);
                              showToast(`Copied ${prompt.id} to clipboard`, "success");
                            }}
                          >
                            <CopyIcon className="text-[var(--text-color)]" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Expandable content */}
                  {expandedPrompt === prompt.id && (
                    <div className="flex flex-col gap-1 pl-2 pr-2 mb-5">
                      {Object.values(prompt.build).map((agent) => (
                        <button
                          onClick={() => {
                            promptsContext?.setSelectedAgent(agent);
                          }}
                          key={agent.id}
                          className="flex flex-col w-full p-2 text-xs border hover:bg-neutral-900 border-[var(--border-line-color)] rounded"
                        >
                          {/* Task ID */}
                          <div className="flex w-full">
                            <div>Task ID:</div>
                            <div className="ml-1">{agent.id}</div>
                          </div>

                          {/* Instructions */}
                          <div className="flex w-full mt-1">
                            <div>Instructions:</div>
                            <div className="ml-1">{agent.instructions}</div>
                          </div>

                          {/* Status */}
                          <div className="flex w-full mt-1">
                            <span>Status:</span>
                            <span
                              className={`ml-1 ${
                                agent.status === "success"
                                  ? "text-green-500"
                                  : agent.status === "pending"
                                  ? "text-yellow-500"
                                  : agent.status === "timeout"
                                  ? "text-neutral-300"
                                  : agent.status === "error"
                                  ? "text-red-500"
                                  : agent.status === "interrupted" ?
                                  "text-orange-500"
                                  :""
                              }`}
                            >
                              {agent.status}
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Dialog for rerunning the entire workflow */}
      <Dialog.Root open={(openDialog && action === "execute")} onOpenChange={setOpenDialog}>
        <Dialog.Trigger asChild />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contrast-50 p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Delete Prompt</h2>
            {promptsContext?.selectedBuild && (
              <form>
                <div className="mb-4">
                  Are you sure you want to run this entire workflow?
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => setOpenDialog(false)}
                    className="bg-neutral-transparent px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e, 'execute')}
                    className="bg-green-500 text-white px-4 py-2 rounded"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* Dialog for deleting the prompt */}
      <Dialog.Root open={(openDialog && action === "delete")} onOpenChange={setOpenDialog}>
        <Dialog.Trigger asChild />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-neutral-900 p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Delete Prompt</h2>
            {promptsContext?.selectedBuild && (
              <form>
                <div className="mb-4">
                  Are you sure you want to delete the prompt: {promptsContext?.selectedBuild.name}?
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => setOpenDialog(false)}
                    className="bg-neutral-transparent px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e, 'delete')}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-500"
                  >
                    Delete
                  </button>
                </div>
              </form>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* Dialog for editing the prompt */}
      <Dialog.Root open={(openDialog && action === "patch")} onOpenChange={setOpenDialog}>
        <Dialog.Trigger asChild />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contrast-50 p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Edit Prompt</h2>
            {promptsContext?.selectedBuild && (
              <form>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm">
                    Prompt Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={promptsContext?.selectedBuild.name}
                    onChange={(e) =>{
                      if (promptsContext.selectedBuild)
                      {
                        promptsContext.setSelectedBuild({
                          ...promptsContext.selectedBuild,
                          name: e.target.value,
                        })
                      }
                    }
                    }
                    className="border p-2 w-full rounded"
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => setOpenDialog(false)}
                    className="bg-neutral-transparent px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => {
                      handleSubmit(e, 'patch'); setOpenDialog(false);}
                    }
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* Dialog for opening the prompt */}
      <Dialog.Root open={(openDialog && action === "patch")} onOpenChange={setOpenDialog}>
        <Dialog.Trigger asChild />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-neutral-900 p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Edit Prompt</h2>
            {promptsContext?.selectedBuild && (
              <form>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm">
                    Prompt Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={promptsContext?.selectedBuild.name}
                    onChange={(e) => {
                      if (promptsContext.selectedBuild) {
                        promptsContext.setSelectedBuild({
                          ...promptsContext.selectedBuild,
                          name: e.target.value,
                        });
                      }}
                    }
                    className="border p-2 w-full rounded text-white bg-neutral-700"
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => setOpenDialog(false)}
                    className="bg-neutral-transparent px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e, 'patch')}
                    className="border-neutral-500 border text-white px-4 py-2 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

    </div>
  );
}
