import React, {useEffect, useState} from "react";
import styles from './document_panel.module.css'
import useAxiosAuthClient from "src/network/interceptors";
import { FiDownload } from "react-icons/fi";
import Spinner from "../../spinners/spinner";

interface FileI{
    name: string
    last_modified: string
}

interface DocumentsListProps {
    folder: string;
}

export function DocumentPanel(){
    return(
        <div className="flex flex-col gap-5 p-[10px]">
            <DocumentsList folder="reports"/>
            {/* <DocumentViewer></DocumentViewer> */}
        </div>
    )
}

export function DocumentSearch(){
    const [filterdDocs, setFilteredDocs] = useState<string>("");
    const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilteredDocs(e.currentTarget.value);
    }
    return (
        <div className={styles.promptFilter}>
            <input className={styles.promptFilterSearch} type="text" onInput={updateInput} />
        </div>
    )
}

export function DocumentViewer(){
    const [documentURL, setDocumentURL] = useState("");

    return (
        <div><iframe src={documentURL}></iframe></div>
    )
}

export default function DocumentsList({ folder }: DocumentsListProps) {
    const [documents, setDocuments] = useState<FileI[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const AxiosAuthClient = useAxiosAuthClient();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await AxiosAuthClient.get(`/files/s3Files/${folder}`);
                setDocuments(response.data);
            } catch (error) {
                setError("Failed to fetch documents");
                console.error("Error fetching documents:", error);
            } finally {
                setLoading(false)
            }
        };
        fetchDocuments();
    }, [folder]);

    const handleDownload = async (name: string) => {
        try {
            const response = await AxiosAuthClient.post(`/files/s3Files/download`, 
                {path:`${folder}/${name}`},
                {responseType: 'blob'},
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error("Error downloading the file:", error);
            setError("Failed to download file");
        }
    };

    return (
        <div className={loading ? "flex items-center justify-center h-full w-full" : ""}>
            {error && <div className="text-red-500">{error}</div>}
            {documents.length > 0 ? (
            documents.map((document) => (
              <div key={document.name} className="flex flex-col gap-1">
                <div className="flex items-center justify-between w-full h-fit p-1 box-border bg-[var(--background-color-contrast)] gap-2 text-[var(--text-color)]">
                  <div className="flex flex-grow items-start justify-between">
                    <span className="text-sm break-words break-all text-[var(--text-color)]">
                      {document.name.length <= 50 ? document.name : document.name.slice(0, 50) + '...'}
                    </span>
                    <span className="text-[var(--text-color)]">{document.last_modified}</span>
                  </div>
                  <button
                    onClick={() => handleDownload(document.name)}
                    className="opacity-50 transition-opacity duration-100 hover:opacity-100"
                  >
                    <i><FiDownload /></i>
                  </button>
                </div>
              </div>
            ))
          ) : null}
        </div>
    );
}