import React, {createContext, useContext, useEffect, useState} from 'react';
import styles from './chat_app.module.css'
import Terminal from '../../components/terminal/terminal'
import ChatNavbar from '../../components/navbars/chat_navbar'
import UserMenu from '../../components/menus/user_menu';
import ControlPanel from '../../components/control_panel/control_panel'
import './chat_app.module.css'

import { useAuth0 } from '@auth0/auth0-react';
import { useIndexedDB } from 'src/client_side_storage/indexed_db';
import { ToastProvider } from 'src/components/notification/toast';
import { Users } from 'lucide-react';
import { Toast } from '@radix-ui/react-toast';

interface AppContextInt{
    accessToken : string | null,
    theme : string,
    setTheme : React.Dispatch<React.SetStateAction<string>>;
    showMenu : boolean;
    setShowMenu : (showMenu : boolean)=>void;
    activeTab : number;
    setActiveTab : (activeTab : number)=>void;
    update : Record<string, boolean> | undefined;
    setUpdate : (updatePrompts : Record<string, boolean>)=>void | undefined;
    userAvatarURL : string | null;
    setAvatarURL : (url : string)=>void;
    currentChat : string | null;
    setCurrentChat : (chat : string)=>void;
}

export const AppContext = createContext<AppContextInt | undefined>(undefined)

export default function ChatApp() {
    const [theme, setTheme] = useState("dark");
    const [showMenu, setShowMenu] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [currentChat, setCurrentChat] = useState<string>("default");
    const [update, setUpdate] = useState<Record<string, boolean> | undefined>({
        "prompts": false
    })
    const [userAvatarURL, setAvatarURL] = useState<string | null>(null);
    const { get, add } = useIndexedDB("app", "users");

    // useEffect(() => {
    //     const fetchChatId = async () => {
    //         try {
    //             const chat_id = await get("");
    //             setCurrentChat(chat_id);
    //         } catch (error) {
    //             console.error("Error fetching current chat id:", error);
    //         }
    //     }
    // }, [])

    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated) {
                try {
                    const current_info = await get(String(user?.sub));
                    if (current_info === undefined) {
                        const response = await fetch(user?.picture || "");
                        const blob = await response.blob();
                        
                        await add({
                            id: String(user?.sub),
                            name: String(user?.name),
                            email: String(user?.email),
                            profile_picture: blob
                        });
                    }

                    const token = await getAccessTokenSilently();
                    setAccessToken(token);
                } catch (error) {
                    console.error("Error fetching access token:", error);
                }
            }
        };

        fetchToken();
    }, [isAuthenticated, getAccessTokenSilently, get, add, user?.sub, user?.picture]);

    return (
        <AppContext.Provider value={{
            update, setUpdate, accessToken, theme, setTheme, showMenu, setShowMenu, activeTab, setActiveTab, userAvatarURL, setAvatarURL,
            currentChat, setCurrentChat,
        }}>
            <div id="main_container" className="font-mono flex flex-row bg-neutral-950 h-[100vh]" data-theme={theme}>
                <ToastProvider>
                    <ChatNavbar></ChatNavbar>
                    <ControlPanel></ControlPanel>
                    <Terminal></Terminal>
                    {showMenu ? <UserMenu/> : null}
                </ToastProvider>
            </div>
        </AppContext.Provider>
    )
}